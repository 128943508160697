









































































import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheLogoWithText from "@/components/TheLogoWithText.vue";
import TheVerticalCenter from "@/components/TheVerticalCenter.vue";
import { register, authenticate } from "@/utils/authentication";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppInput,
    AppLabel,
    AppWidget,
    TheLogoWithText,
    TheVerticalCenter,
  },
})
export default class Join extends Vue {
  user: pro.User = {};
  notification: Notification | null = null;
  loading = false;

  get token(): string | undefined {
    return this.$route.params.token;
  }

  hasRequiredFields(): boolean {
    if (!this.user.fullName || !this.user.password) return false;
    if (!this.token && !this.user.emailAddress) return false;
    return true;
  }

  async join() {
    this.loading = true;
    const password = this.user.password;
    try {
      this.user = await register(this.user, this.token);
      this.user.password = password;
      await authenticate(this.user);
      this.redirect();
    } catch (err) {
      this.notification = createErrorNotification(err.message);
    } finally {
      this.loading = false;
    }
  }

  async redirect() {
    if (this.token) {
      await this.$router.push({ name: "HOME" });
    } else {
      await this.$router.push({ name: "VERIFY_EMAIL" });
    }
  }
}
